// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration=uat` replaces `environment.ts` with `environment.uat.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'uat',
	production: false,
  sandbox: false,
	apiPath: 'https://qa-spd-uat.ey.com/api/',
	mock: false,
	msal: {
		clientId: 'a7108188-9b69-49e0-8060-fb81577e32fa',
		redirectUri: 'https://qa-spd-uat.ey.com',
		authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
		consentScopes: [
			'user.read',
			'openid',
			'profile',
		],
		protectedResourceKey: 'https://qa-spd-uat.ey.com/api/*',
		protectedResourceValues: ['api://a7108188-9b69-49e0-8060-fb81577e32fa/access_as_user']
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI. //added uncommented this
